import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Count from '../Milestone/_Count'
import { ProgressBar, ProgressLabel } from '../Progress'
import TaskList from './_TaskList'
import TaskItem from './_TaskItem'
import http from '../../http'

import {
  FiUser,
  FiSettings,
  FiCheck
} from 'react-icons/fi'

import {
  MdOutlinePending
} from 'react-icons/md'

const Profile = () => {
  const [ profile, setProfile ] = useState()
  const [ tasks, setTasks ] = useState( [] )
  const [ searchParams, setSearchParams ] = useSearchParams()

  useEffect( () => {
    if ( profile ) return

    http
      .get( '/api/user/me' )
      .then( ( { data } ) => {
        setProfile( data )

        return http
          .get( '/api/task', { params: {
            assignees: data._id,
            status: { $ne: 'deleted' },
            __all: true,
            __raw: true,
            __select: 'status weight'
          } } )
      } )
      .then( ( { data } ) => setTasks( data ) )
  } )

  const fetchTasks = () => {
    let status = searchParams.get( 'status' ) || 'open'
    if ( status === 'working' )
      status = { $in: [ 'working', 'paused' ] }

    const params = {
      __all: true,
      status,
      assignees: profile._id,
      __populate: [
        'milestone:title',
        'assignees:username photo'
      ]
    }

    return http
      .get( '/api/task', { params } )
  }

  const getStatus = () => {
    return searchParams.get( 'status' ) || 'open'
  }

  const setStatus = ( status ) => {
    setSearchParams( { status } )
  }

  const isActive = ( status ) => {
    return getStatus() === status ? 'active' : ''
  }

  if ( ! profile )
    return null

  return <main className="profile">
    <header>
      <div className="meta">
        <div className="identity">
          { /*
          <div className="photo">
            <img src="https://randomuser.me/api/portraits/women/28.jpg" alt={ profile.username } />
          </div>
          */ }
          <div className="photo">
            { profile.photo ?
              <img src={ profile.photo } alt={ profile.username } />
            : <FiUser className="placeholder" /> }
          </div>

          <div>
            <h1>{ profile.username }</h1>

            <span>
              <strong>{ tasks.length }</strong> tasks
            </span>
          </div>
        </div>

        <nav className="counters">
          <button
            className={ isActive( 'open' ) }
            onClick={ () => setStatus( 'open' ) }
          >
            <MdOutlinePending />

            <Count endpoint="/api/task/count" params={ {
              assignees: profile._id,
              status: 'open'
            } } /> open
          </button>

          <button
            className={ isActive( 'working' ) }
            onClick={ () => setStatus( 'working' ) }
          >
            <FiSettings />

            <Count endpoint="/api/task/count" params={ {
              assignees: profile._id,
              status: { $in: [ 'working', 'paused' ] }
            } } /> working
          </button>

          <button
            className={ isActive( 'closed' ) }
            onClick={ () => setStatus( 'closed' ) }
          >
            <FiCheck />

            <Count endpoint="/api/task/count" params={ {
              assignees: profile._id,
              status: 'closed'
            } } /> closed
          </button>
        </nav>

      </div>

      <div className="progress-wrap">
        <ProgressBar items={ tasks } />

        <ProgressLabel items={ tasks } />
      </div>

    </header>

    <TaskList fetch={ fetchTasks } TaskItem={ TaskItem } />

  </main>
}

export default Profile
