import { useRef, useState } from 'react'
import { FiSettings, FiCheck } from 'react-icons/fi'
import { generateColorList } from '../../../fn'

const TaskAsideTags = ( { model, setModel } ) => {
  const [ isFormOpen, setIsFormOpen ] = useState( false )
  const [ colors, setColors ] = useState( generateColorList( model.tags.length ) )
  const inputRef = useRef( null )

  const toggleForm = () => {
    setIsFormOpen( ! isFormOpen )
  }

  const add = () => {
    const { value } = inputRef.current
    let { tags } = model

    const hasTag = tags.indexOf( value ) !== -1
    if ( hasTag ) return

    setModel( { tags: [ ...tags, value ] } )
    inputRef.current.value = ''
  }

  const remove = ( tag ) => {
    let { tags } = model

    tags = tags.filter( t => t !== tag )

    setModel( { tags } )
  }

  const handleKeyDown = ( e ) => {
    if ( e.keyCode !== 13 ) return

    add()
  }

  return <div className="item tags">
    <div className="header"
      onClick={ toggleForm }
    >
      <h1>tags</h1>

      { isFormOpen ? <FiCheck /> : <FiSettings /> }
    </div>

    { isFormOpen ? <div className="control">
      <div className="input">
        <input
          type="text"
          placeholder="new tag"
          className="one"
          ref={ inputRef }
          onKeyDown={ handleKeyDown }
          size={ 1 }
        />

        <button onClick={ add }>
          add tag
        </button>
      </div>

      <div className="tag-list">
        { model.tags.map( ( t, ti ) => <button
          key={ ti }
          onClick={ () => remove( t ) }
        >
          { t }
        </button> ) }
      </div>
    </div> : <div className="tag-list">
      { model.tags.length === 0 ? <span>
        none yet
      </span> : model.tags.map( ( t, ti ) => <span
        key={ ti }
        className="tag"
        style={ {
          ...colors[ ti ]
        } }
      >
        { t }
      </span> ) }
    </div> }
  </div>

}

export default TaskAsideTags
