import { useState } from 'react'
import { FiSettings, FiCheck } from 'react-icons/fi'

const TaskAsideEffort = ( { model, setModel } ) => {
  const [ isFormOpen, setIsFormOpen ] = useState( false )

  const toggleForm = () => {
    setIsFormOpen( ! isFormOpen )
  }

  const handleChange = ( e ) => {
    const { value } = e.target

    setModel( { weight: value } )
  }

  return <div className="item effort">
    <div className="header"
      onClick={ toggleForm }
    >
      <h1>effort</h1>

      { isFormOpen ? <FiCheck /> : <FiSettings /> }
    </div>

    { isFormOpen ? <div className="control">
      <input
        type="range"
        min={ 1 }
        max={ 5 }
        step={ 1 }
        size={ 1 }
        className="one"
        value={ model.weight }
        onChange={ handleChange }
      />

      <span>
        { model.weight }
      </span>

      </div> : <div className="vs gap">
        <progress
          min={ 0 }
          max={ 5 }
          value={ model.weight }
          className="one"
        />

        <span>
          { model.weight }
        </span>
      </div>
    }
  </div>

}

export default TaskAsideEffort
