import { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import http from '../../http'

import { FiX, FiSave } from 'react-icons/fi'

const MilestoneForm = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [ model, setModel ] = useState( {
    title: '',
    description: '',
    startDate: '',
    dueDate: '',
    assignees: []
  } )

  const [ userList, setUserList ] = useState( [] )
  const [ selectedUser ] = useState( '' )

  useEffect( () => {
    if ( params.milestoneId ) {
      http
        .get( `/api/milestone/${ params.milestoneId }`, { params: {
          __populate: [
            'assignees:username'
          ]
        } } )
        .then( response => {
          let newModel = {
            ...model,
            ...response.data
          }

          const { dueDate, startDate } = response.data
          if ( dueDate )
            newModel = { ...newModel, dueDate: dueDate.substr( 0, 10 ) }

          if ( startDate )
            newModel = { ...newModel, startDate: startDate.substr( 0, 10 ) }

          newModel.assignees = newModel.assignees.map( u => u._id )

          setModel( newModel )
        } )
    }

    if ( userList.length !== 0 )
      return

    http
      .get( '/api/user', { params: {
        __all: true,
        __raw: true,
        __select: 'username'
      } } )
      .then( response => {
        setUserList( response.data )
      } )
  }, [ params.milestoneId ] )


  const submitButton = useRef( null )

  const handleChange = ( e ) => {
    const { name, value } = e.target
    setModel( { ...model, [ name ]: value } )
  }

  const handleAssigneeChange = ( e ) => {
    const { value } = e.target

    assignUser( value )
  }

  const assignUser = ( userId ) => {
    let { assignees } = model

    if ( assignees.indexOf( userId ) !== -1 )
      return

    assignees.push( userId )
    setModel( { ...model, assignees } )
  }

  const removeAssignation = ( userId ) => {
    const assignees = model.assignees.filter( uId => uId !== userId )

    setModel( { ...model, assignees } )
  }

  const saveMilestone = ( e ) => {
    e.preventDefault()

    const data = { ...model }

    let options = {
      url: '/api/milestone',
      method: 'POST',
      data
    }

    if ( data._id ) {
      options = {
        ...options,
        url: `/api/milestone/${ data._id }`,
        method: 'PUT'
      }
    }

    http( options )
      .then( response => {
        const { _id } = response.data
        navigate( `/milestones/${ _id }` )
      } )
  }

  if ( userList.length === 0 )
    return null

  return <main className="one hs">
    <header className="bar">
      <nav className="one vs gap">
        <Link to="/milestones">milestones</Link>

        <strong>
          { model._id ? 'edit' : 'new' }
        </strong>
      </nav>

      <button onClick={ () => submitButton.current.click() } title="save">
        <FiSave />
      </button>

      <button onClick={ () => navigate( '/milestones' ) } title="cancel">
        <FiX />
      </button>
    </header>

    <section className="one hs gap padding overflow-y">
      <form onSubmit={ saveMilestone }>
        <div className="hs">
          <label>title</label>

          <input
            type="text"
            name="title"
            placeholder="title"
            size={ 1 }
            required
            className="one"
            value={ model.title }
            onChange={ handleChange }
          />
        </div>

        <div className="assignees">
          <div className="control">
            <label>assignees</label>

            <div className="vs">
              <select
                value={ selectedUser }
                onChange={ handleAssigneeChange }
                size={ 1 }
              >
                <option value="" disabled>choose user</option>
                { userList.map( ( user, index ) => (
                  <option
                    value={ user._id }
                    key={ index }
                  >{ user.username }</option>
                ) ) }
              </select>

              <button
                type="button"
                onClick={ () => assignUser( localStorage.getItem( 'userId' ) ) }
              >
                assign yourself
              </button>
            </div>
          </div>

          <div className="assignees-list">
            { model.assignees.map( ( userId, index ) => {
              const user = userList.find( u => u._id === userId )

              return <button
                type="button"
                key={ index }
                onClick={ () => removeAssignation( userId ) }
              >
                { user.username }
              </button>
            } ) }
          </div>
        </div>

        <div className="hs">
          <label>start date</label>

          <input
            type="date"
            name="startDate"
            size={ 1 }
            className="one"
            value={ model.startDate }
            onChange={ handleChange }
          />
        </div>

        <div className="hs">
          <label>due date</label>

          <input
            type="date"
            name="dueDate"
            size={ 1 }
            className="one"
            value={ model.dueDate }
            onChange={ handleChange }
          />
        </div>

        <div className="hs">
          <label>description</label>

          <textarea
            name="description"
            placeholder="milestone description"
            size={ 1 }
            className="one"
            value={ model.description }
            onChange={ handleChange }
          />
        </div>

        <button type="submit" className="hidden" ref={ submitButton } />
      </form>
    </section>
  </main>
}

export default MilestoneForm
