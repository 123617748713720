import { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import TaskItem from './_Item'
import Placeholder from './Placeholder'
import http from '../../http'

const TaskList = ( props ) => {
  const params = useParams()
  const [ searchParams ] = useSearchParams()

  const [ state, setState ] = useState( {
    page: [],
    total: 0,
    limit: 10,
    offset: 0
  } )

  useEffect( () => {
    let status = searchParams.get( 'status' ) || 'open'
    const { milestoneId: milestone } = params

    if ( status === 'working' )
      status = { $in: [ 'working', 'paused' ] }

    http
      .get( '/api/task', { params: {
        __all: true,
        status,
        milestone,
        __populate: [
          'assignees:username photo',
          'by:username photo'
        ]
      } } )
      .then( response => {
        setState( response.data )
      } )
  }, [ params, searchParams ] )

  if ( state.page.length === 0 )
    return <Placeholder />

  return <section className="one task-list">
    { state.page.map( ( t, index ) => <TaskItem
      data={ t }
      key={ index }
    /> ) }
  </section>
}

export default TaskList
