import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import http from '../../http'
import * as RandomString from 'randomstring'

import {
  FiSave,
  FiX
} from 'react-icons/fi'

const UserForm = () => {
  const navigate = useNavigate()
  const submitButton = useRef( null )
  const [ isFetched ] = useState( false )
  const [ roleList, setRoleList ] = useState( [] )

  const [ model, setModel ] = useState( {
    username: '',
    password: RandomString.generate(),
    role: ''
  } )

  useEffect( () => {
    http
      .get( '/api/role', { params: {
        __select: 'name',
        __all: true,
        __raw: true
      } } )
      .then( ( { data } ) => setRoleList( data ) )
  }, [ isFetched ] )

  const handleChange = ( e ) => {
    const { name, value } = e.target
    setModel( { ...model, [ name ]: value } )
  }

  const save = ( e ) => {
    e.preventDefault()

    http
      .post( '/api/user', model )
      .then( ( { data } ) => {
        navigate( '/user' )
      } )
  }

  return <main className="one hs">
    <header className="bar">
      <nav className="one breadcrumb">
        <ul>
          <li><Link to="/user">users</Link></li>

          <li><strong>new</strong></li>
        </ul>
      </nav>

      <button onClick={ () => submitButton.current.click() }>
        <FiSave />
      </button>

      <button onClick={ () => navigate( '/user' ) }>
        <FiX />
      </button>
    </header>

    <section className="one hs gap padding overflow-y">
      <form onSubmit={ save }>
        <div className="hs">
          <label>email</label>

          <input
            type="email"
            name="username"
            placeholder="email"
            size={ 1 }
            required
            className="one"
            value={ model.username }
            onChange={ handleChange }
          />
        </div>

        <div className="hs">
          <label>role</label>

          <div className="vs">
            <select
              name="role"
              value={ model.role }
              onChange={ handleChange }
              required
              className="one"
            >
              <option value="" disabled>choose role</option>
              { roleList.map( ( role, index ) => (
                <option
                  value={ role._id }
                  key={ index }
                >
                  { role.name }
                </option>
              ) ) }
            </select>
          </div>
        </div>

        <button type="submit" className="hidden" ref={ submitButton } />
      </form>
    </section>
  </main>
}

export default UserForm
