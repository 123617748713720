import { Link } from 'react-router-dom'
import OwnerBubble from '../OwnerBubble'

const TaskItem = ( { data } ) => {
  return <div className="task-item">
    <nav>
      <ul>
        <li><Link to={ `/milestones/${ data.milestone._id }` }>
          { data.milestone.title }
        </Link></li>

        <li><Link to={ `/milestones/${ data.milestone._id }/task/${ data._id }` }>
          { data.title }
        </Link></li>
      </ul>
    </nav>

    <div className="assignees">
      { data.assignees.map( ( u, ui ) => <OwnerBubble { ...u } key={ ui } /> ) }
    </div>
  </div>
}

export default TaskItem
