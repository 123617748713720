import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { withForceUpdate } from '../../context/ForceUpdate'
import http from '../../http'
import OwnerBubble from '../OwnerBubble'
import DiffInDays from '../DiffInDays'
import TaskAside from './Aside'
import TaskComment from './_Comment'
import ElapsedTime from './ElapsedTime'
import { FiCheck, FiRefreshCcw, FiPlay, FiPause, FiMessageSquare } from 'react-icons/fi'

const TaskDetail = ( { withForceUpdate: { updatedCount } } ) => {
  const params = useParams()

  const [ task, setTask ] = useState()
  const [ milestone, setMilestone ] = useState()

  useEffect( () => {
    http
      .get( `/api/milestone/${ params.milestoneId }`, { params: {
        __select: 'title'
      } } )
      .then( response => {
        setMilestone( response.data )

        return http
          .get( `/api/task/${ params.taskId }`, { params: {
            __populate: [
              'comments:taskId content type action by.username by.photo at',
              'assignees:username photo',
              'by:username photo'
            ]
          } } )
      } )
      .then( response => {
        setTask( response.data )
      } )
  }, [ params.taskId, updatedCount ])

  const update = ( model ) => {
    http( {
      url: `/api/task/${ params.taskId }`,
      method: 'PUT',
      params: {
        __populate: [
          'comments:taskId content type action by.username by.photo at',
          'assignees:username photo',
          'by:username photo'
        ]
      },
      data: model
    } )
      .then( ( { data } ) => {
        console.log( { update: data } )
        setTask( data )
      } )
  }

  const actionIcon = ( action ) => {
    switch ( action ) {
      case 'close':
        return <FiCheck />
      case 'reopen':
        return <FiRefreshCcw />
      case 'working':
        return <FiPlay />
      case 'pause':
        return <FiPause />
      default:
        return <>unrecognized action</>
    }
  }

  if ( ! task )
    return null

  return <main className="task-detail">
    <header className="bar">
      <nav className="breadcrumb">
        <ul>
          <li><Link to="/milestones">milestones</Link></li>

          <li><Link to={ `/milestones/${ params.milestoneId }` }>{ milestone.title }</Link></li>

          <li><Link to={ `/milestones/${ params.milestoneId }/task/${ params.taskId }` }>{ task.title }</Link></li>
        </ul>
      </nav>
    </header>

    <header className="meta">
      <h1>{ task.title }</h1>

      <div className="vs gap">
        <span className={ [ 'status', task.status ].join( ' ' ) }>
          { task.status }
        </span>

        <span>
          <strong>{ task.by.username }</strong>&nbsp;
          opened this task&nbsp;
          <DiffInDays { ...task } />
        </span>
      </div>
    </header>

    <section>

      <div className="main">

        <div className="comments">

        {
          task.comments
            .map( ( comment, index ) => {
              switch( comment.type ) {
                case 'text':
                  return <div className="comment text" key={ index }>
                    <div className="icon">
                      <FiMessageSquare />
                    </div>

                    <div className="body">
                      <div className="meta">
                        <OwnerBubble { ...comment.by } />

                        <strong><Link to={ `/user/${ comment.by._id }` }>{
                          comment.by.username
                        }</Link></strong>
                        commented <DiffInDays { ...comment } />
                      </div>

                      { comment.content ? <div className="content">
                        <p>
                          { comment.content }
                        </p>
                      </div> : null }
                    </div>
                  </div>

                case 'action':
                  return <div className="comment action" key={ index }>
                    <div className="icon">
                      { actionIcon( comment.action ) }
                    </div>

                    <div className="body">
                      <div className="meta">
                        <OwnerBubble { ...comment.by } />

                        <strong><Link to={ `/user/${ comment.by._id }` }>{
                          comment.by.username
                        }</Link></strong> {
                          comment.action === 'close' ?
                          'closed' :
                          comment.action === 'reopen' ? 
                          'reopened' :
                          comment.action === 'working' ?
                          'started working' :
                          comment.action === 'pause' ?
                          'paused'
                          : null
                        } this <DiffInDays { ...comment } />
                      </div>

                      { comment.content ? <div className="content">
                        <p>
                          { comment.content }
                        </p>
                      </div> : null }
                    </div>

                  </div>

                default:
                  return <div key={ index }>
                    not implemented yet
                  </div>
              }
            } )
        }
        </div>

        <TaskComment task={ task } />

      </div>

      <div className="aside">

        <ElapsedTime task={ task } />

        <TaskAside model={ task } setModel={ update } />

      </div>

    </section>
  </main>
}

export default withForceUpdate( TaskDetail )
