import moment from 'moment'

const DiffInDays = ( { at } ) => {
  const now = moment()

  const days = now.diff( moment( at ), 'days' )

  const hours = now.diff( moment( at ), 'hours' )

  const minutes = now.diff( moment( at ), 'minutes' )

  if ( hours <= 1 )
    return <>{ minutes } minutes ago</>

  if ( days <= 1 )
    return <>{ hours } hours ago</>

  return <>{ days } days ago</>
}

export default DiffInDays
