import moment from 'moment'

const EnlapsedTimeCalculate = ( { task } ) => {
  let seconds = 0
  let prev = null

  const getFraction = ( seconds, unit ) => {
    const units = Math.floor( seconds / unit )
    const rest = seconds % unit
    return [ units, rest ]
  }

  const formatTime = ( seconds ) => {
    let rest = seconds

    return [
      [ 60 * 60 * 24, 'day', 'days' ],
      [ 60 * 60, 'hour', 'hours' ],
      [ 60, 'minute', 'minutes' ],
      [ 1, 'second', 'seconds' ]
    ]
      .map( ( [ unit, sin, plu ] ) => {
        const [ n, r ] = getFraction( rest, unit )
        rest = r

        return { n, unit: n > 1 ? plu : sin }
      } )
      .filter( ( { n } ) => n > 0 )
      .map( ( { n, unit } ) => <span><strong>{ n }</strong><i>{ unit }</i></span> )
  }

  task.comments
    .filter( comment => comment.action )
    .filter( comment => [ 'working', 'pause', 'close' ].indexOf( comment.action ) !== -1 )
    .forEach( comment => {

      if ( prev && [ 'pause', 'close' ].indexOf( comment.action ) !== -1  ) {
        const cur = moment( comment.at ).unix()
        seconds += cur - prev
        prev = null
      }

      if ( comment.action === 'working' )
        prev = moment( comment.at ).unix()

    } )

  return <div className="task-aside">
    <div className="item elapsed-time">
      <div className="header">
        <h1>elapsed time</h1>
      </div>

      <div className="time">
        { formatTime( seconds ) }
      </div>
    </div>
  </div>
}

export default EnlapsedTimeCalculate
