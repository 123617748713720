import { useEffect, useState } from 'react'
import { useParams, useSearchParams, useNavigate, Link } from 'react-router-dom'
import moment from 'moment'
import http from '../../http'
import { TaskList } from '../Task'
import { ProgressBar, ProgressLabel } from '../Progress'
import Count from './_Count'

import { FiCheck, FiSettings, FiEdit3, FiPlus } from 'react-icons/fi'
import { MdOutlinePending } from 'react-icons/md'

const MilestoneDetail = () => {
  const navigate = useNavigate()
  const { milestoneId } = useParams()
  const [ milestone, setMilestone ] = useState()
  const [ searchParams, setSearchParams ] = useSearchParams()

  useEffect( () => {
    if ( milestone ) return

    http
      .get( `/api/milestone/${ milestoneId }`, { params: {
        __populate: [
          'tasks:weight status'
        ]
      } } )
      .then( response => {
        setMilestone( response.data )
      } )
  } )

  const isExpired = ( dueDate ) => {
    if ( ! dueDate ) return false

    const dueAt = moment( dueDate )
    const today = moment()

    return today.isAfter( dueAt )
  }

  const goToCreate = () => {
    navigate( `/milestones/${ milestoneId }/task/new` )
  }

  const getStatus = () => {
    return searchParams.get( 'status' ) || 'open'
  }

  const setStatus = ( status ) => {
    setSearchParams( { status } )
  }

  const isActive = ( status ) => {
    return getStatus() === status ? 'active' : ''
  }

  if ( ! milestone ) return null

  return <main className="one hs milestone">
    <header className="bar">
      <nav className="one breadcrumb">
        <ul>
          <li><Link to="/milestones">milestones</Link></li>

          <li><Link to={ `/milestones/${ milestone._id }` } >
          { milestone.title }
          </Link></li>
        </ul>
      </nav>

      <button onClick={ () => navigate( `/milestones/${ milestone._id }/edit` ) } title="edit milestone">
        <FiEdit3 />
      </button>

      <button onClick={ goToCreate } title="add task">
        <FiPlus />
      </button>

    </header>

    <header className="hs metadata">
      <div className="vs gap">
        <span className={ [ 'status', milestone.status ].join( ' ' ) }>
          { milestone.status === 'closed' || milestone.status === 'archived' ?
            milestone.status
          : milestone.status === 'open' && isExpired( milestone.dueDate ) ?
            'expired'
          : 'open' }
        </span>

        <strong>milestone</strong>

        { milestone.dueDate ? <span>{
          isExpired( milestone.dueDate ) ?
            `expired on ${ moment( milestone.dueDate ).format( 'll' ) }`
          : `expires on ${ moment( milestone.dueDate ).format( 'll' ) }`
        }</span>
        : null }
      </div>

      <div className="title">
        <h1>{ milestone.title }</h1>

        { milestone.description && milestone.description !== '' ? <p>
          { milestone.description }
        </p> : null }
      </div>

      <div className="hs">
        <ProgressBar items={ milestone.tasks } />

        <ProgressLabel items={ milestone.tasks } />
      </div>

    </header>

    <nav className="tabs">
      <button
        className={ isActive( 'open' ) }
        onClick={ () => setStatus( 'open' ) }
      >
        <MdOutlinePending />

        <Count endpoint="/api/task/count" params={ {
          milestone: milestone._id,
          status: 'open'
        } } /> open
      </button>

      <button
        className={ isActive( 'working' ) }
        onClick={ () => setStatus( 'working' ) }
      >
        <FiSettings />

        <Count endpoint="/api/task/count" params={ {
          milestone: milestone._id,
          status: { $in: [ 'working', 'paused' ] }
        } } /> working
      </button>

      <button
        className={ isActive( 'closed' ) }
        onClick={ () => setStatus( 'closed' ) }
      >
        <FiCheck />

        <Count endpoint="/api/task/count" params={ {
          milestone: milestone._id,
          status: 'closed'
        } } /> closed
      </button>

    </nav>

    <TaskList />

  </main>
}

export default MilestoneDetail
