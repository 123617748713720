import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import MilestoneItem from './_Item'
import Placeholder from './Placeholder'
import http from '../../http'
import { withForceUpdate } from '../../context/ForceUpdate'

const MilestoneList = ( {
  withForceUpdate: { updatedCount }
} ) => {
  const [ searchParams ] = useSearchParams()

  const [ state, setState ] = useState( {
    page: [],
    total: 0,
    limit: 10,
    offset: 0
  } )

  useEffect( () => {
    const status = searchParams.get( 'status' ) || 'open'

    http
      .get( '/api/milestone', { params: {
        __all: true,
        status,
        __populate: [
          'tasks:status weight'
        ]
      } } )
      .then( response => {
        setState( response.data )
      } )
  }, [ searchParams, updatedCount ] )

  if ( state.page.length === 0 )
    return <Placeholder />

  return state.page.map( ( m, index ) => <MilestoneItem
    data={ m }
    key={ index }
  /> )
}

export default withForceUpdate( MilestoneList )
