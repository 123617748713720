import axios from 'axios'

const http = axios.create( {
  baseUrl: process.env.REACT_APP_BASE_URL || ''
} )

http.interceptors.request.use( config => {
  let token = localStorage.getItem( 'token' )

  config.headers[ 'Authorization' ] = token

  return config
} )

http.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if ( error.response.status === 401 && !error.config.rawError ) {
      localStorage.clear()
      return window.location.replace( '/auth/login' )
    }

    throw error
  }
)

export default http
