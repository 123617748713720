const sum = ( items ) => {
  return items
    .reduce( ( acc, cur ) => ( acc += cur.weight ), 0 )
}

const ProgressBar = ( { items } ) => {
  const total = sum( items ) || 1

  const current = sum(
    items.filter( ( item ) => item.status === 'closed' )
  )

  const amount = current * 100 / total
  const percent = `${ amount }%`

  return <div className="progress-container">
    <div className="progress" style={ { width: percent } } />
  </div>
}

const ProgressLabel = ( { items } ) => {
  const total = sum( items ) || 1

  const current = sum(
    items.filter( ( item ) => item.status === 'closed' )
  )

  const amount = current * 100 / total

  let percent = '0%'
  if ( amount > 0 )
    percent = `${ amount.toFixed( 2 ) }%`

  return <div><strong>{ percent }</strong> complete</div>
}

export {
  ProgressBar,
  ProgressLabel
}
