import { useNavigate, useSearchParams } from 'react-router-dom'
import MilestoneList from './_List'
import Count from './_Count'
import { FiCheck, FiArchive, FiPlus } from 'react-icons/fi'
import { GoMilestone } from 'react-icons/go'

const MilestonesMain = () => {
  const navigate = useNavigate()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const getStatus = () => {
    return searchParams.get( 'status' ) || 'open'
  }

  const setStatus = ( status ) => {
    setSearchParams( { status } )
  }

  const isActive = ( status ) => {
    return getStatus() === status ? 'active' : ''
  }

  return (
    <main className="one hs milestones">
      <header className="bar">
        <h1 className="one">milestones</h1>

        <button className="success" onClick={ () => navigate( '/milestones/new' ) }>
          <FiPlus />
        </button>
      </header>

      <nav className="vs gap">
        <button className={ isActive( 'open' ) } onClick={ () => setStatus( 'open' ) }>
          <GoMilestone />

          <Count params={ { status: 'open' } } /> open
        </button>

        <button className={ isActive( 'closed' ) } onClick={ () => setStatus( 'closed' ) }>
          <FiCheck />

          <Count params={ { status: 'closed' } } /> closed
        </button>

        <button className={ isActive( 'archived' ) } onClick={ () => setStatus( 'archived' ) }>
          <FiArchive />

          <Count params={ { status: 'archived' } } /> archived
        </button>
      </nav>

      <section className="one hs gap padding overflow-y">

        <MilestoneList />

      </section>
    </main>
  )
}

export default MilestonesMain
