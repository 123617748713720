import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import OpenPlaceholder from './_Open'
import ClosedPlaceholder from './_Closed'
import ArchivedPlaceholder from './_Archived'

const Placeholder = () => {
  const [ searchParams ] = useSearchParams()
  const [ status, setStatus ] = useState( 'open' )

  useEffect( () => {
    setStatus( searchParams.get( 'status' ) || 'open' )
  }, [ searchParams ] )

  switch( status ) {
    case 'open':
      return <OpenPlaceholder />
    case 'closed': 
      return <ClosedPlaceholder />
    case 'archived':
      return <ArchivedPlaceholder />
  }
}

export default Placeholder
