import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import http from '../../http'

const UserRecoveryPasswd = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [ model, setModel ] = useState( {
    password: '',
    repassword: ''
  } )

  const [ hasError, setHasError ] = useState( false )

  const handleChange = ( e ) => {
    let { name, value } = e.target

    if ( value === '' )
      value = undefined

    setModel( { ...model, [ name ]: value } )
  }

  const submit = ( e ) => {
    e.preventDefault()

    const { recoveryKey } = params
    const { password } = model

    http
      .put( '/api/auth/reset', {
        recoveryKey,
        password
      } )
      .then( ( { data } ) => {
        navigate( '/auth/login' )
      } )
      .catch( err => {
        setHasError( true )
        setTimeout( () => {
          setHasError( false )
        }, 7000 )
      } )
  }

  return <main className="one hs justify-center align-center">
    <section className="w-300x hs gap">
      <div>
        <h1 className="bold f-7x">reset password</h1>
      </div>

      <form onSubmit={ submit } className="hs gap">
        <div className="hs">
          <label>new password</label>

          <input
            type="password"
            name="password"
            placeholder="new password"
            size={ 1 }
            required
            value={ model.password }
            onChange={ handleChange }
          />
        </div>

        <div className="hs">
          <label>confirm new password</label>

          <input
            type="password"
            name="repassword"
            placeholder="confirm new password"
            size={ 1 }
            required
            value={ model.repassword }
            onChange={ handleChange }
          />
        </div>

        { hasError ?
          <div className="hs error">
            <p>invalid reset link.</p>
          </div>
        : null }

        <div className="vs flex-end">
          <button
            type="submit"
            disabled={ model.password !== model.repassword || model.password === '' || model.password === '' }
          >
            continue
          </button>
        </div>

      </form>

    </section>
  </main>
}

export default UserRecoveryPasswd
