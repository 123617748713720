import {
  FiUser,
  FiList
} from 'react-icons/fi'

const Icon = ( { name, ...props } ) => {
  if ( ! name )
    return <>icon name is required</>

  let IconAlias = <></>

  switch( name ) {
    case 'FiUser':
      IconAlias = FiUser
      break
    case 'FiList':
      IconAlias = FiList
      break
  }

  return <IconAlias { ...props } />
}

export default Icon
