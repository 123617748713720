import http from '../../../http'
import Calculate from './_Calculate'
import { withForceUpdate } from '../../../context/ForceUpdate'
import { FiPlay, FiPause } from 'react-icons/fi'

const EnlapsedTime = ( {
  task,
  withForceUpdate: { forceUpdate }
} ) => {

  const sendComment = ( action ) => {
    http
      .put( `/api/task/${ task._id }/addComment`, {
        taskId: task._id,
        type: 'action',
        action
      } )
      .then( ( { data }  ) => {
        forceUpdate()
      } )
  }

  const working = () => {
    sendComment( 'working' )
  }

  const pause = () => {
    sendComment( 'pause' )
  }

  if ( task.status === 'closed' )
    return <Calculate task={ task } />

  return <div className="task-aside">
    <div className="item elapsed-time">
      <div className="header">
        <h1>elapsed time</h1>
      </div>

      <div className="control">
        { task.status === 'open' || task.status === 'paused' ?
          <button
            onClick={ working }
            title="work in"
            className="action"
          >
            <FiPlay />
          </button>
        : null }

        { task.status === 'working' ?
          <button
            onClick={ pause }
            title="pause working"
            className="action"
          >
            <FiPause />
          </button>
        : null }

        <button>
          close as completed
        </button>
      </div>
    </div>
  </div>
}

export default withForceUpdate( EnlapsedTime )
