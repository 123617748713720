import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ProgressBar, ProgressLabel } from '../Progress'
import Confirm from '../Confirm'
import http from '../../http'
import { withForceUpdate } from '../../context/ForceUpdate'

const MilestoneItem = ( {
  data,
  withForceUpdate: { forceUpdate }
} ) => {
  const navigate = useNavigate()

  const countTasksByStatus = ( arr, status ) => {
    if ( status instanceof Array === false )
      status = [ status ]

    return arr
      .filter( ( it ) => status.indexOf( it.status ) !== -1 )
      .length
  }

  const editMilestone = () => {
    navigate( `/milestones/${ data._id }/edit` )
  }

  const deleteMilestone = () => {
    http
      .delete( `/api/milestone/${ data._id }` )
      .then( response => {
        forceUpdate()
      } )
  }

  const setMilestoneStatus = ( status ) => {
    http
      .put( `/api/milestone/${ data._id }`, { status } )
      .then( response => {
        forceUpdate()
      } )
  }

  const toggleStatus = () => {
    const newStatus = data.status === 'open' ? 'closed' : 'open'

    setMilestoneStatus( newStatus )
  }

  const archiveMilestone = () => {
    setMilestoneStatus( 'archived' )
  }

  return <div className="vs gap milestone-item">
    <div className="one hs milestone-identity">
      <div>
        <Link to={ `/milestones/${ data._id }` }>{ data.title }</Link>
      </div>

      { data.dueDate ? <div className="dueDate">
        <p>expires on { moment( data.dueDate ).format( 'll' ) }</p>
      </div> : null }
    </div>

    <div className="one hs milestone-progress">
      <ProgressBar items={ data.tasks } />

      <div className="vs gap">
        <div>
          <ProgressLabel items={ data.tasks } />
        </div>

        <div>
          <strong>{ countTasksByStatus( data.tasks, 'open' ) }</strong> open
        </div>

        <div>
          <strong>{ countTasksByStatus( data.tasks, [ 'working', 'paused' ] ) }</strong> working
        </div>

        <div>
          <strong>{ countTasksByStatus( data.tasks, 'closed' ) }</strong> closed
        </div>
      </div>

      <div className="vs gap">
        <button onClick={ editMilestone }>
          edit
        </button>

        <Confirm action={ deleteMilestone }>
          <span>delete</span>

          <span className="danger-color">confirm</span>
        </Confirm>

        { data.status !== 'archived' ? <button onClick={ archiveMilestone }>
          archive
        </button> : null }
      </div>
    </div>

    <div>
      <button onClick={ toggleStatus }>
        { data.status === 'open' ? 'close' : 'reopen' }
      </button>
    </div>

  </div>
}

export default withForceUpdate( MilestoneItem )
