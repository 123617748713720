import { useState } from 'react'
import { withForceUpdate } from '../../context/ForceUpdate'
import http from '../../http'
import OwnerBubble from '../OwnerBubble'
import { FiCheck, FiRefreshCcw } from 'react-icons/fi'

const TaskComment = ( {
  task,
  withForceUpdate: { forceUpdate }
} ) => {
  const [ content, setContent ] = useState( '' )

  const me = localStorage.getItem( 'username' )

  const update = ( e ) => {
    const { value } = e.target
    setContent( value )
  }

  const actionComment = ( e ) => {
    const action = task.status !== 'closed' ? 'close' : 'reopen'

    http
      .put( `/api/task/${ task._id }/addComment`, {
        taskId: task._id,
        type: 'action',
        action,
        content
      } )
      .then( ( { data } ) => {
        setContent( '' )
        forceUpdate()
      } )
  }

  const justComment = ( e ) => {
    http
      .put( `/api/task/${ task._id }/addComment`, {
        taskId: task._id,
        content
      } )
      .then( ( { data } ) => {
        setContent( '' )
        forceUpdate()
      } )
  }

  return <footer className="leave-comment">
    <OwnerBubble username={ me } />

    <div className="form">
      <textarea
        placeholder="leave comment"
        className="one"
        size={ 1 }
        value={ content }
        onChange={ update }
      />

      <div className="actions">
        <button onClick={ actionComment }>
          { task.status !== 'closed' ? <>
            <FiCheck /> close task { content && content !== '' ? 'with comment' : null }
          </> : <>
            <FiRefreshCcw /> reopen task
          </> }
        </button>

        <button
          onClick={ justComment }
          disabled={ ! content }
        >
          comment
        </button>
      </div>
    </div>
  </footer>
}

export default withForceUpdate( TaskComment )
