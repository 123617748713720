import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './style/style.sass'
import { Aside, Blank } from './components/Layout'
import { MilestonesMain, MilestoneForm, MilestoneDetail } from './components/Milestone'
import { TaskForm, TaskDetail } from './components/Task'
import { UserList, UserDetail, UserForm, UserResetPasswd, Profile } from './components/User'
import Redirect from './components/Redirect'
import Login from './components/Login'
import Guard from './components/Guard'
import ForceUpdate from './context/ForceUpdate'

/*
  * status: pending, working, done
  */

const App = () => {

  return (
    <ForceUpdate>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={ Guard }>
            <Route path="/" Component={ Aside }>
              <Route path="milestones" Component={ Blank }>
                <Route path="new" Component={ MilestoneForm } />

                <Route path=":milestoneId" Component={ MilestoneDetail } />

                <Route path=":milestoneId/edit" Component={ MilestoneForm } />

                <Route path=":milestoneId/task/new" Component={ TaskForm } />

                <Route path=":milestoneId/task/:taskId" Component={ TaskDetail } />

                <Route
                  index
                  Component={ MilestonesMain }
                />
              </Route>

              <Route path="user" Component={ Blank }>
                <Route path="new" Component={ UserForm } />

                <Route path=":userId" Component={ UserDetail } />

                <Route
                  index
                  Component={ UserList }
                />
              </Route>

              <Route path="me" Component={ Profile } />
            </Route>

            <Route
              index
              element={ <Redirect to="/milestones" /> }
            />

            <Route
              path="*"
              element={ <Redirect to="/milestones" /> }
            />
          </Route>


          <Route path="auth" Component={ Blank }>
            <Route path="login" Component={ Login } />

            <Route path="reset/:recoveryKey" Component={ UserResetPasswd } />
          </Route>
        </Routes>
      </BrowserRouter>
    </ForceUpdate>
  )
}

export default App
