import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import http from '../http'

import { FiUser, FiLock, FiEye, FiEyeOff, FiAlertTriangle, FiAlertCircle } from 'react-icons/fi'

import logo from '../assets/logo.png'

const Login = () => {
  const navigate = useNavigate()

  const [ error, setError ] = useState()

  const [ passwdType, setPasswdType ] = useState( 'password' )
  const [ credentials, setCredentials ] = useState( {
    username: '',
    password: ''
  } )

  const togglePasswd = () => {
    setPasswdType( passwdType === 'password' ? 'text' : 'password' )
  }

  const updateCredentials = ( value ) => {
    setCredentials( { ...credentials, ...value } )
  }

  const login = ( e ) => {
    e.preventDefault()

    http( {
      url: '/api/auth/login',
      method: 'POST',
      data: credentials,
      rawError: true
    } )
      .then( ( response ) => {
        const { data: { token } } = response
        localStorage.setItem( 'token', token )
        navigate( '/' )
      } )
      .catch( err => {
        const code = err?.response?.status || 0
        setError( code )
        setTimeout( () => {
          setError()
        }, 4900 )

      } )
  }

  return (
    <main className="one hs justify-center align-center login">
      <form onSubmit={ login } className="w-49x hs padding gap">
        <div className="one vs justify-center padding">
          <img src={ logo } className="w-14x" />
        </div>

        <div className="one vs form-control">
          <div className="icon">
            <FiUser />
          </div>

          <input
            type="text"
            name="username"
            placeholder="username"
            size={ 1 }
            required
            className="one"
            value={ credentials.username }
            onChange={ ( { target: { value: username } } ) => updateCredentials( { username } ) }
          />
        </div>

        <div className="one vs form-control">
          <div className="icon">
            { credentials.password === '' ?
              <FiLock />
            : passwdType === 'password' ?
              <FiEyeOff onClick={ togglePasswd } />
            : <FiEye onClick={ togglePasswd } /> }
          </div>

          <input
            type={ passwdType }
            name="password"
            placeholder="password"
            size={ 1 }
            required
            className="one"
            value={ credentials.password }
            onChange={ ( { target: { value: password } } ) => updateCredentials( { password } ) }
          />
        </div>


        { error === 404 ?
            <div className="one vs padding alert warn">
              <div className="alert-icon">
                <FiAlertTriangle />
              </div>

              <div className="alert-message">
                <p>invalid user</p>
              </div>
            </div>
          : error === 401 ?
            <div className="one vs padding alert error">
              <div className="alert-icon">
                <FiAlertCircle />
              </div>

              <div className="alert-message">
                <p>incorrect password</p>
              </div>
            </div>
          : null
        }

        <div className="one vs flex-end">
          <Link>forgot password</Link>
        </div>

        <div className="vs flex-end">
          <button type="submit">
            enter
          </button>
        </div>
      </form>
    </main>
  )
}

export default Login
