import { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import http from '../../http'
import TaskAside from './Aside'

import { FiX } from 'react-icons/fi'

const TaskForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [ model, setModel ] = useState( {
    milestone: params.milestoneId,
    title: '',
    dueDate: '',
    weight: 1,
    status: 'open',
    assignees: [],
    labels: [],
    tags: [],
    comments: []
  } )

  const [ comment, setComment ] = useState()

  const [ userList, setUserList ] = useState( [] )

  useEffect( () => {
    if ( userList.length !== 0 )
      return

    http
      .get( '/api/user', { params: {
        __all: true,
        __raw: true,
        __select: 'username'
      } } )
      .then( response => {
        setUserList( response.data )
      } )
  } )

  const update = ( data ) => {
    setModel( { ...model, ...data } )
  }

  const handleChange = ( e ) => {
    let { name, value } = e.target

    if ( value === '' )
      value = undefined

    setModel( { ...model, [ name ]: value } )
  }

  const handleTextChange = ( e, update ) => {
    let { value } = e.target

    if ( value === '' )
      value = undefined

    update( value )
  }

  const goToDetails = ( id ) => {
    navigate( `/milestones/${ params.milestoneId }/task/${ id }` )
  }

  const addComment = async ( taskId, content ) => {
    return await http
      .put( `/api/task/${ taskId }/addComment`, {
        taskId,
        content
      } )
  }

  const addTask = async ( taskId ) => {
    return await http
      .put( `/api/milestone/${ params.milestoneId }/addTask`, { taskId } )
  }


  const save = async ( e ) => {
    e.preventDefault()

    try {
      const { data: task } = await http
        .post( '/api/task', model )

      await addTask( task._id )

      if ( comment )
        await addComment( task._id, comment )

      goToDetails( task._id )
    } catch ( err ) {
      console.log( { err } )
    }

  }

  return <main className="one hs">
    <header className="bar">
      <nav className="one breadcrumb">
        <ul>
          <li><Link to={ `/milestones/${ params.milestoneId }` }>milestone</Link></li>

          <li><strong>new task</strong></li>
        </ul>
      </nav>

      <button
        onClick={ () => navigate( `/milestones/${ params.milestoneId }` ) }
        title="cancel"
      >
        <FiX />
      </button>
    </header>

    <section className="one vs gap padding overflow-y">
      <div className="two hs gap overflow-y">
        <form onSubmit={ save } className="one hs gap">
          <div className="hs">
            <label>title</label>

            <input
              type="text"
              name="title"
              placeholder="title"
              size={ 1 }
              required
              value={ model.title }
              onChange={ handleChange }
            />
          </div>

          <div className="hs">
            <textarea
              name="description"
              placeholder="leave a comment"
              size={ 1 }
              className="one"
              value={ comment }
              onChange={ ( e ) => handleTextChange( e, setComment ) }
            />
          </div>

          <div className="vs flex-end">
            <button type="submit">
              submit new task
            </button>
          </div>
        </form>
      </div>

      <TaskAside model={ model } setModel={ update } />
    </section>

  </main>
}

export default TaskForm
