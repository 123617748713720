import { useState, useEffect } from 'react'
import http from '../../../http'
import OwnerBubble from '../../OwnerBubble'
import { generateColorList } from '../../../fn'
import { FiSettings, FiCheck } from 'react-icons/fi'

const TaskAsideAssignees = ( { model, setModel } ) => {
  const [ isFormOpen, setIsFormOpen ] = useState( false )
  const [ userList, setUserList ] = useState( [] )
  const [ colors, setColors ] = useState()
  const userSelectorDefaultValue = ''

  useEffect( () => {
    if ( userList.length !== 0 ) return

    http
      .get( '/api/user', { params: {
        __all: true,
        __raw: true,
        __select: 'username'
      } } )
      .then( response => {
        setUserList( response.data )
        setColors( generateColorList( response.data.length ) )
      } )
  } )

  const myself = localStorage.getItem( 'userId' )

  const toggleForm = () => {
    setIsFormOpen( ! isFormOpen )
  }

  const assign = ( uId ) => {
    let { assignees } = model

    const isAssigned = assignees
      .map( u => u._id || u )
      .find( ( u ) => u === uId )

    if ( isAssigned ) return

    assignees.push( uId )

    setModel( { assignees } )
  }

  const unassign = ( uId ) => {
    const assignees = model.assignees
      .map( u => u._id || u )
      .filter( u => u !== uId )

    setModel( { assignees } )
  }

  const handleUserSelectorChange = ( e ) => {
    e.preventDefault()
    const { value } = e.target

    assign( value )
  }

  if ( userList.length === 0 )
    return null

  return <div className="item assignees">
    <div className="header"
      onClick={ toggleForm }
    >
      <h1>assignees</h1>

      { isFormOpen ? <FiCheck /> : <FiSettings /> }
    </div>

    { isFormOpen ? <div className="control">
      <select
        value={ userSelectorDefaultValue }
        onChange={ handleUserSelectorChange }
        size={ 1 }
      >
        <option value="" disabled>choose user</option>
        { userList.map( ( user, index ) => <option
          value={ user._id }
          key={ index }
        >{ user.username }</option> ) }
      </select>
    </div> : null }

    <div className="assignee-list">
      { model.assignees.length === 0 ? <span>
        no one
      </span> :
        model.assignees
          .map( u => u._id || u )
          .map( ( userId, index ) => {
            const user = userList.find( u => u._id === userId )

            return <OwnerBubble
              { ...user }
              key={ index }
              onClick={ () => unassign( userId ) }
              style={ { ...colors[ index ] } }
            />
          } )
      }

      {
        model.assignees
          .map( u => u._id || u )
          .find( u => u === myself ) ?
        null :
        <button
          onClick={ () => assign( myself ) }
        >
          assign yourself
        </button>
      }
    </div>
  </div>
}

export default TaskAsideAssignees
