const colorList = [
  '#e93a61',
  '#a8c88b',
  '#ffa851',
  '#996b83',
  '#1c7cbc',
  '#9390b1',
  '#c62c48',
  '#2fc1b2',
  '#7fb2d7',
  '#ecbfe0',
  '#d4e1a3',
  '#bbac74',
  '#ffb37b',
  '#82688d',
  '#6ca9a9',
  '#e1daf2',
  '#e5de73',
  '#75895b',
  '#9f656b',
  '#fef3d2',
]

const generateColorList = ( n ) => {
  let c = [ ...colorList ]
  let total = colorList.length

  let x = n / total
  if ( x >= 1 ) {
    x = Math.ceil( x )
    for( let i = 1; i < x; i++ )
      c = [ ...c, ...colorList ]
  }

  total = c.length
  const initAt = Math.floor( Math.random() * total )

  const a = c.splice( initAt )

  return [ ...a, ...c ]
    .map( color => ( {
      background: color,
      color: generateTextColor( color )
    } ) )
}

const generateTextColor = ( color ) => {
  const luminance = { r: 0.2126, g: 0.7152, b:0.0722 }

  let r = parseInt( color.substring( 1, 3 ), 16 )
  let g = parseInt( color.substring( 3, 5 ), 16 )
  let b = parseInt( color.substring( 5 ), 16 )

  let rgb = [ r / 255, g / 255, b / 255 ]
  for ( let i = rgb.length; i--; )
    rgb[i] = rgb[ i ] <= 0.03928 ? rgb[ i ] / 12.92 : Math.pow( ( ( rgb[i] + 0.055 ) / 1.055 ), 2.4 )

  let thresh = (
    ( luminance.r * rgb[ 0 ] ) +
    ( luminance.g * rgb[ 1 ] ) +
    ( luminance.b * rgb[ 2 ] )
  )

  if ( thresh > 0.22 )
    return '#222'

  return '#ccc'
}

export default generateColorList
