import { Link } from 'react-router-dom'
import UpdateRole from './_UpdateRole'
import Confirm from '../Confirm'
import http from '../../http'
import { withForceUpdate } from '../../context/ForceUpdate'

import {
  FiTrash,
  FiAlertCircle
} from 'react-icons/fi'

import {
  MdLockReset
} from 'react-icons/md'


const UserItem = ( {
  user,
  withForceUpdate: { forceUpdate }
} ) => {

  const recoveryPassword = () => {
    http
      .put( '/api/auth/recovery', user )
      .then( ( { data } ) => {
        console.log( data )
      } )
  }

  const _delete = () => {
    http
      .delete( `/api/user/${ user._id }` )
      .then( () => {
        forceUpdate()
      } )
  }

  return <tr>
    <td><Link to={ `/user/${ user._id }` }>{ user.username }</Link></td>
    <td><UpdateRole data={ user } /></td>
    <td className="actions x2">
      <button
        title="recovery password"
        onClick={ recoveryPassword }
      >
        <MdLockReset />
      </button>

      <Confirm action={ _delete }>
        <FiTrash />

        <FiAlertCircle className="danger-color"/>
      </Confirm>
    </td>
  </tr>
}

export default withForceUpdate( UserItem )
