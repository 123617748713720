import { useNavigate, useParams, createSearchParams } from 'react-router-dom'
import Placeholder from '../../Placeholder'

const WorkingPlaceholder = ( props ) => {
  const navigate = useNavigate()
  const params = useParams()

  const go = () => {
    navigate( {
      pathname: `/milestones/${ params.milestoneId }`,
      search: `?${ createSearchParams( { status: 'open' } ) }`
    } )
  }

  return <Placeholder>
    <h1>there are no working task on this milestone.</h1>

    <p>go to your <i>open</i> tasks tab, then start to <i>work in</i> some existing one.</p>

    <button onClick={ go }>go to open tasks</button>
  </Placeholder>
}

export default WorkingPlaceholder
