const OwnerBubble = ( { username, photo, onClick = () => {}, style } ) => {

  const initials = ( owner ) => {
    const parts = owner
      .trim()
      .replaceAll( /[.-_]/g, ' ' )
      .split( ' ' )

    if ( parts.length === 1 )
      return parts[ 0 ].slice( 0, 2 )

    return parts[ 0 ].slice( 0, 1 ) + parts[ 1 ].slice( 0, 1 )
  }

  if ( ! username )
    return <>unamed</>

  return (
    <button className="owner" style={ style } title={ username } onClick={ onClick }>
      <label>
        { initials( username ) }
      </label>
    </button>
  )
}

export default OwnerBubble
