import { useState } from 'react'
import moment from 'moment'
import { FiSettings, FiCheck } from 'react-icons/fi'

const TaskAsideDue = ( { model, setModel } ) => {
  const [ isFormOpen, setIsFormOpen ] = useState( false )

  const toggleForm = () => {
    setIsFormOpen( ! isFormOpen )
  }

  const handleChange = ( e ) => {
    const { value } = e.target

    setModel( { dueDate: value } )
  }

  return <div className="item due">
    <div className="header"
      onClick={ toggleForm }
    >
      <h1>due</h1>

      { isFormOpen ? <FiCheck /> : <FiSettings /> }
    </div>

    { isFormOpen ? <div className="hs">
      <input
        type="date"
        value={ model.dueDate }
        onChange={ handleChange }
        className="one"
      />
    </div> : null }

    <div>
      <span>
        { model.dueDate ?
          moment( model.dueDate ).format( 'll' ) :
          'no due date assigned'
        }
      </span>
    </div>
  </div>

}

export default TaskAsideDue
