import { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import http from '../../http'

const TaskList = ( {
  fetch,
  Placeholder = () => <>placeholder</>,
  TaskItem = () => <>item</>
} ) => {
  const params = useParams()
  const [ searchParams ] = useSearchParams()

  const [ state, setState ] = useState( {
    page: [],
    total: 0,
    limit: 10,
    offset: 0
  } )

  useEffect( () => {
    fetch()
      .then( ( { data } ) => {
        setState( data )
      } )
  }, [ params, searchParams ] )

  if ( state.page.length === 0 )
    return <Placeholder />

  return <section className="task-list">
    { state.page.map( ( t, index ) => <TaskItem
      data={ t }
      key={ index }
    /> ) }
  </section>
}

export default TaskList
