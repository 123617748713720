import { useState, useEffect } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import Icon from '../Icon'
import http from '../../http'

import logo from '../../assets/logo.png'

import {
  FiPower,
  FiUser
} from 'react-icons/fi'

const Aside = () => {
  const navigate = useNavigate()
  const [ isFetched ] = useState( false )
  const [ menu, setMenu ] = useState( [] )

  useEffect( () => {
    http
      .get( '/api/user/menu' )
      .then( ( { data } ) => {
        setMenu( data )
      } )
  }, [ isFetched ] )

  const profile = () => {
    navigate( '/me' )
  }

  const logout = () => {
    localStorage.clear()
    navigate( '/auth/login' )
  }

  return (
    <>
      <aside className="w-300x hs">
        <header className="bar justify-center">
          <img src={ logo } className="logo" />
        </header>

        <section className="one">
          <nav className="hs">
            { menu.map( ( item, index ) => {
              return <NavLink
                key={ index }
                to={ item.link }
              >
                <div className="icon">
                  <Icon name={ item.icon } />
                </div>

                <span>
                  { item.label }
                </span>
              </NavLink>
            } ) }
          </nav>
        </section>

        <footer className="bar space-between">
          <button onClick={ logout }>
            <FiPower />
          </button>

          <button onClick={ profile }>
            <FiUser />
          </button>

        </footer>
      </aside>

      <Outlet />
    </>
  )
}

export default Aside
