import { useNavigate } from 'react-router-dom'
import Placeholder from '../../Placeholder'

const OpenPlaceholder = ( props ) => {
  const navigate = useNavigate()

  const go = () => {
    navigate( '/milestones/new' )
  }

  return <Placeholder>
    <h1>there are no open milestones.</h1>

    <p>create milestones to help to organize your team work.</p>

    <button onClick={ go }>create new milestone</button>
  </Placeholder>
}

export default OpenPlaceholder
