import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import OpenPlaceholder from './_Open'
import WorkingPlaceholder from './_Working'
import ClosedPlaceholder from './_Closed'

const Placeholder = () => {
  const [ searchParams ] = useSearchParams()
  const [ status, setStatus ] = useState( 'open' )

  useEffect( () => {
    setStatus( searchParams.get( 'status' ) || 'open' )
  }, [ searchParams ] )

  switch( status ) {
    case 'open':
      return <OpenPlaceholder />
    case 'working':
      return <WorkingPlaceholder />
    case 'closed': 
      return <ClosedPlaceholder />
  }
}

export default Placeholder
