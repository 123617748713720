import { useState } from 'react'

const Confirm = ( props ) => {
  const [ to, setTo ] = useState()
  const [ buttonIndex, setButtonIndex ] = useState( 0 )
  const [ maxClick, setMaxClick ] = useState( 0 )

  const next = () => {
    if ( to )
      clearTimeout( to )

    setTo( setTimeout( () => {
      setButtonIndex( 0 )
    }, props.timeout || 3000 ) )

    let { children } = props
    if ( children instanceof Array === false )
      children = [ children ]

    if ( ( children.length - 1 ) !== buttonIndex )
      setButtonIndex( buttonIndex + 1 )
    else
      props.action()
  }

  if ( ! props.children )
    return <>confirm must have child nodes</>

  let { children } = props
  if ( children instanceof Array === false )
    children = [ children ]

  return <button onClick={ next }>{ children[ buttonIndex ] }</button>
}

export default Confirm
