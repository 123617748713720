import { useState, useEffect } from 'react'
import { withForceUpdate } from '../../context/ForceUpdate'
import http from '../../http'

import { FiX } from 'react-icons/fi'

const UpdateRole = ( {
  data,
  withForceUpdate: { forceUpdate }
} ) => {
  const [ isFetch ] = useState( true )
  const [ selectedRole ] = useState( '' )
  const [ roleList, setRoleList ] = useState( [] )
  const [ isOpen, setIsOpen ] = useState( false )

  useEffect( () => {
    http
      .get( '/api/role', { params: {
        __all: true,
        __raw: true,
        __select: 'name'
      } } )
      .then( ( { data } ) => {
        setRoleList( data )
      } )
  }, [ isFetch ] )

  const toggle = () => {
    setIsOpen( ! isOpen )
  }

  const handleRoleChange = ( e ) => {
    e.preventDefault()
    const { value } = e.target

    http
      .put( `/api/user/${ data._id }`, {
        role: value
      } )
      .then( ( { data } ) => {
        toggle()
        forceUpdate()
      } )
  }

  return <div className="control">
    { isOpen ? <div>
      <select
        value={ selectedRole }
        onChange={ handleRoleChange }
        size="1"
      >
        <option value="" disabled>choose role</option>
        { roleList.map( ( role, index ) => <option
          value={ role._id }
          key={ index }
        >{ role.name }</option> ) }
      </select>

      <button onClick={ () => toggle() }>
        <FiX />
      </button>
    </div> : <span className="cursor-pointer" onClick={ toggle }>{ data.role?.name }</span> }
  </div>
}

export default withForceUpdate( UpdateRole )
