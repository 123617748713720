import Assignees from './_Assignees'
import Due from './_Due'
import Effort from './_Effort'
import Tags from './_Tags'

const TaskAsideContainer = ( props ) => {

  return <div className="one task-aside">
    <Assignees { ...props } />

    <Due { ...props } />

    <Effort { ...props } />

    <Tags { ...props } />
  </div>
}

export default TaskAsideContainer
