import { useNavigate, createSearchParams } from 'react-router-dom'
import Placeholder from '../../Placeholder'

const ClosedPlaceholder = ( props ) => {
  const navigate = useNavigate()

  const go = () => {
    navigate( {
      pathname: '/milestones',
      search: `?${ createSearchParams( { status: 'open' } ) }`
    } )
  }

  return <Placeholder>
    <h1>there are no <i>closed</i> milestones.</h1>

    <button onClick={ go }>go to open milestones</button>
  </Placeholder>
}

export default ClosedPlaceholder
