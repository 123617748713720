import { useNavigate, useParams } from 'react-router-dom'
import Placeholder from '../../Placeholder'

const OpenPlaceholder = ( props ) => {
  const navigate = useNavigate()
  const params = useParams()

  const go = () => {
    navigate( `/milestones/${ params.milestoneId }/task/new` )
  }

  return <Placeholder>
    <h1>there are no open task on this milestone.</h1>

    <p>add task to milestones to help to organize your team work.</p>

    <button onClick={ go }>create new task</button>
  </Placeholder>
}

export default OpenPlaceholder
