import { createContext, useState } from 'react'

const ForceUpdateContext = createContext()

const ForceUpdate = ( props ) => {
  const [ updatedCount, setUpdatedCount ] = useState( 0 )

  const forceUpdate = () => {
    setUpdatedCount( updatedCount + 1 )
  }

  return <ForceUpdateContext.Provider value={ {
    updatedCount,
    forceUpdate
  } }>
    { props.children }
  </ForceUpdateContext.Provider>
}

const withForceUpdate = ( ComponentAlias ) => {
  return props => <ForceUpdateContext.Consumer>
    { context => <ComponentAlias { ...props } withForceUpdate={ context } /> }
  </ForceUpdateContext.Consumer>
}

export default ForceUpdate

export {
  ForceUpdateContext,
  withForceUpdate
}
