import { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import TaskList from './_TaskList'
import TaskItem from './_TaskItem'
import { ProgressBar, ProgressLabel } from '../Progress'
import Count from '../Milestone/_Count'
import http from '../../http'

import { FiUser, FiSettings, FiCheck } from 'react-icons/fi'
import { MdOutlinePending } from 'react-icons/md'

const UserDetail = () => {
  const params = useParams()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ user, setUser ] = useState()
  const [ tasks, setTasks ] = useState( [] )

  useEffect( () => {
    http
      .get( `/api/user/${ params.userId }` )
      .then( ( { data } ) => {
        setUser( data )

        return http
          .get( '/api/task', {
            params: {
              assignees: data._id,
              status: { $ne: 'deleted' },
              __all: true,
              __raw: true,
              __select: 'status weight'
            }
          } )
      } )
      .then( ( { data } ) => {
        setTasks( data )
      } )
  }, [ params ] )

  const fetchTasks = () => {
    let status = searchParams.get( 'status' ) || 'open'
    if ( status === 'working' )
      status = { $in: [ 'working', 'paused' ] }

    const params = {
      __all: true,
      status,
      assignees: user._id,
      __populate: [
        'milestone:title',
        'assignees:username photo'
      ]
    }

    return http
      .get( '/api/task', { params } )
  }

  const getStatus = () => {
    return searchParams.get( 'status' ) || 'open'
  }

  const setStatus = ( status ) => {
    setSearchParams( { status } )
  }

  const isActive = ( status ) => {
    return getStatus() === status ? 'active' : ''
  }

  if ( ! user ) return

  return <section className="profile">
    <header>

      <div className="meta">
        <div className="identity">
          <div className="photo">
            { user.photo ?
              <img src={ user.photo } alt="profile photo" />
            : <FiUser className="placeholder" /> }
          </div>

          <div>
            <h1>{ user.username }</h1>

            <span>
              <strong>{ tasks.length }</strong> tasks
            </span>
          </div>
        </div>

        <nav className="counters">
          <button
            className={ isActive( 'open' ) }
            onClick={ () => setStatus( 'open' ) }
          >
            <MdOutlinePending />

            <Count endpoint="/api/task/count" params={ {
              assignees: user._id,
              status: 'open'
            } } /> open
          </button>

          <button
            className={ isActive( 'working' ) }
            onClick={ () => setStatus( 'working' ) }
          >
            <FiSettings />

            <Count endpoint="/api/task/count" params={ {
              assignees: user._id,
              status: { $in: [ 'working', 'paused' ] }
            } } /> working
          </button>

          <button
            className={ isActive( 'closed' ) }
            onClick={ () => setStatus( 'closed' ) }
          >
            <FiCheck />

            <Count endpoint="/api/task/count" params={ {
              assignees: user._id,
              status: 'closed'
            } } /> closed
          </button>

        </nav>

      </div>

      <div className="progress-wrap">
        <ProgressBar items={ tasks } />

        <ProgressLabel items={ tasks } />
      </div>

    </header>

    <TaskList fetch={ fetchTasks } TaskItem={ TaskItem } />

  </section>
}

export default UserDetail
