import { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { generateColorList } from '../../fn'
import OwnerBubble from '../OwnerBubble'

const TaskItem = ( { data } ) => {
  const params = useParams()
  const [ colors, setColors ] = useState( generateColorList( data.assignees.length ) )

  return <div className="task">
    <div className="title">
      <Link to={ `/milestones/${ params.milestoneId }/task/${ data._id }` }>
        { data.title }
      </Link>
    </div>

    <div className="assignees">
      { data.assignees.map( ( u, ui ) => <OwnerBubble
        { ...u }
        key={ ui }
        style={ { ...colors[ ui ] } }
      /> ) }
    </div>
  </div>
}

export default TaskItem
