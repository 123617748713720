import { useNavigate, useParams, createSearchParams } from 'react-router-dom'
import Placeholder from '../../Placeholder'

const ClosedPlaceholder = ( props ) => {
  const navigate = useNavigate()
  const params = useParams()

  const go = () => {
    navigate( {
      pathname: `/milestones/${ params.milestoneId }`,
      search: `?${ createSearchParams( { status: 'working' } ) }`
    } )
  }

  return <Placeholder>
    <h1>there are no <i>closed</i> tasks on this milestone.</h1>

    <p>please <i>work in</i> task assigments to see the closed ones on this tab.</p>

    <button onClick={ go }>go to working tasks</button>
  </Placeholder>
}

export default ClosedPlaceholder
