import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../http'
import { withForceUpdate } from '../../context/ForceUpdate'

import { FiPlus } from 'react-icons/fi'

import UserItem from './_Item'

const UserList = ( {
  withForceUpdate: { updatedCount }
} ) => {
  const navigate = useNavigate()
  const [ isFetched ] = useState( false )
  const [ state, setState ] = useState( {
    page: [],
    total: 0,
    limit: 10,
    offset: 0
  } )

  useEffect( () => {
    http
      .get( '/api/user', { params: {
        __all: true,
        status: { $ne: 'deleted' },
        __select: 'username photo',
        __populate: 'role:name'
      } } )
      .then( ( { data } ) => {
        setState( data )
      } )
  }, [ isFetched, updatedCount ] )

  return <main className="one users">
    <header className="bar">
      <h1 className="one">users</h1>

      <button
        onClick={ () => { navigate( '/user/new' ) } }
        title="add new user"
      >
        <FiPlus />
      </button>
    </header>

    <section className="user-list">
      <table>
        <thead>
          <tr>
            <td>name</td>
            <td>role</td>
            <td className="actions x2"></td>
          </tr>
        </thead>

        <tbody>
          { state.page.map( ( u, index ) => (
            <UserItem user={ u } key={ index } />
          ) ) }
        </tbody>
      </table>

    </section>
  </main>
}

export default withForceUpdate( UserList )
