import { useState, useEffect } from 'react'
import http from '../../http'
import { withForceUpdate } from '../../context/ForceUpdate'

const Count = ( {
  endpoint = '/api/milestone/count',
  params,
  withForceUpdate: { updatedCount }
} ) => {
  const [ count, setCount ] = useState( 0 )

  useEffect( () => {
    http
      .get( endpoint, { params } )
      .then( response => {
        const { count: total } = response.data
        setCount( total )
      } )
  }, [ params, endpoint, updatedCount ] )

  return <>{ count }</>
}

export default withForceUpdate( Count )
